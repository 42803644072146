<template>
    <div class="icph-page-network-directory">
        <h1 class="icph-page-title">Network Directory</h1>
        <div class="icph-view-toggles">
            <div>
                <label>Data Visualisation</label>
                <SwitchButton
                    v-model:checked="data_visualisation"
                    on-text="ON"
                    off-text="OFF"
                >
                </SwitchButton>
            </div>
        </div>
        <div class="icph-nd-visualisation" v-if="data_visualisation">
            <panel
                class="icph-tabs-panel"
                :dark="true"
                >
                <ul class="icph-tabs" :data-selected="current_view">
                    <li @click="select_view(0)">Global View</li>
                    <li @click="select_view(1)">Thematic Focus</li>
                    <li @click="select_view(2)">Geographic Focus</li>
                </ul>
            </panel>
            <div class="icph-nd-info">
                <p v-if="current_view === 0">The Global View showcases an interactive matrix that clearly delineates the roles of Secretariat and Global Team members within the network. This matrix is a visualization of the primary roles and functions across the network. By clicking on a role, users can view a list of Secretariat and Global Team members fulfilling each specific function. Selecting a member&#39;s name opens a detailed profile page that provides further insights into the individual&#39;s focus areas, both thematic and regional, alongside their contact information.</p>
                <p v-if="current_view === 1">The Thematic View organizes Global Team members into clusters based on their thematic areas of work. These clusters are visualized as boxes of different sizes, reflecting the number of team members associated with each thematic area. Clicking on box brings up the profiles of the Global Team members involved.<br /><i>* Please be aware that some Global Team members may appear in multiple thematic areas, reflecting the broad scope of their work which spans beyond a singular topic.</i></p>
                <p v-if="current_view === 2">The Regional View presents an interactive map that highlights the geographical areas where Global Team members focus their efforts. Instead of showing where team members are located, this view emphasizes the regions and countries where they focus their work. These areas are marked by circles of varying sizes, representing the concentration of team members in each area. Clicking on the name of the region will show you all the profiles of Global Team members who work in that area. Selecting a member's name circle in a region opens a detailed profile page.<br /><i>* Please be aware that some Global Team members may appear in multiple regions, reflecting the broad scope of their work which spans beyond a singular location.</i></p>
            </div>
            <div class="icph-nd-views" :data-selected="current_view">
                <div class="icph-view">
                    <icph-chart
                        v-if="!loading"
                        :loading="loading"
                        :layout="layouts[current_view]"
                        :dataset="chart_dataset"
                        :key="'layout_' + current_view"
                        >
                    </icph-chart>
                </div>
            </div>
        </div>
        <div class="icph-nd-directory">
            <panel
                class="icph-search-directory"
                :dark="true"
                >
                <base-input
                    :round="true"
                    v-model="search_term"
                    icon-left="icon-search"
                    label="Search contacts..."
                    placeholder="Search"
                    v-on:keyup.stop.enter="apply_filters"
                    >
                </base-input>
            </panel>
            <panel
                class="icph-filters"
                :dark="true"
                >
                <dropdown
                    label="Topic"
                    placeholder="Select your topics"
                    v-model="model.topic"
                    :round="true"
                    :options="topics"
                    @update:modelValue="apply_filters"
                    >
                </dropdown>
                <dropdown
                    label="Location"
                    placeholder="Select countries"
                    v-model="model.countries"
                    mode="multiple"
                    @update:modelValue="apply_filters"
                    :round="true"
                    :options="countries"
                    >
                </dropdown>
                <dropdown
                    label="Role"
                    placeholder="Select role"
                    v-model="model.icp_role"
                    @update:modelValue="apply_filters"
                    :round="true"
                    :options="icp_roles"
                    >
                </dropdown>
            </panel>
            <Button class="icph-search-button" icon="icon-search" color="primary">
                Search
            </Button>
            <team :loading="loading">
                <team-member
                    v-for="(member, key) in members"
                    :member="member.id"
                    :image="get_member_picture(member)"
                    :name="member.name"
                    :work-title="''"
                    :lead="member.lead"
                    :company="member.organization"
                    :tags="member.thematic_scope"
                    :key="key"
                    >
                </team-member>
                <Button class="icph-load-more" v-if="has_more_pages" color="primary" @click.prevent="load_more">
                    Load More
                </Button>
            </team>
        </div>
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Dropdown from '@/components/Inputs/Dropdown';
    import Button from '@/components/Buttons/Button';
    import SwitchButton from "@/components/Buttons/SwitchButton";
    import Team from '@/components/Team/Team';
    import TeamMember from '@/components/Team/TeamMember';
    import IcphChart from '@/components/ICPHChart/ICPHChart';

    import { NetworkDirectoryService, UserService, TopicService, CountryService } from '@/services';

    export default
    {
        name: 'NetworkDirectory',
        components:
        {
            Panel,
            BaseInput,
            Dropdown,
            Button,
            SwitchButton,
            Team,
            TeamMember,
            IcphChart,
        },
        data()
        {
            return {
                loading: true,
                has_more_pages: false,
                data_visualisation: true,
                current_view: 0,
                per_page: 15,
                last_page: 1,
                page: 1,
                topics: [],
                countries: [],
                members: [],
                global_view_data: null,
                thematic_scope_data: null,
                geographic_focus_data: null,
                chart_dataset: {},
                icp_roles: [
                    {
                        label: 'Secretariat',
                        value: 'secretariat',
                    },
                    {
                        label: 'Lead',
                        value: 'WG Lead',
                    }
                ],
                search_term: '',
                model:
                {
                    topic: '',
                    countries: [],
                },
                layouts:
                [
                    'force',
                    'tree',
                    'circle-packing',
                ],
            };
        },
        async mounted()
        {
            this.loading = true;

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.topics.forEach((item) => {
                item.value = item.name;
                item.label = item.name;
            })

            await this.get_members();
            // await this.get_chart_data();

            await this.select_view(0);

            setTimeout(() =>
            {
                this.loading = false;
            }, 100);
        },
        methods:
        {
            async get_members()
            {
                const response = (await UserService.all({
                    page: this.page,
                    term: this.search_term,
                    countries: this.model.countries,
                    topic: this.model.topic,
                    icp_role: this.model.icp_role,
                    'per-page': 15,
                    'with-role': 'admin,global-team'
                })).data;
                const members = response.data;

                if (this.page === 1)
                {
                    this.members = members;
                }
                else
                {
                    this.members.push(...members);
                }

                this.last_page = response.meta.last_page;
                this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async get_chart_data(view)
            {
                if (view === 0 && !this.global_view_data)
                {
                    this.global_view_data = (await NetworkDirectoryService.global_view_data()).data;
                }

                if (view === 1 && !this.thematic_focus_data)
                {
                    this.thematic_focus_data = (await NetworkDirectoryService.thematic_focus_data()).data;
                }

                if (view === 2 && !this.geographic_focus_data)
                {
                    this.geographic_focus_data = (await NetworkDirectoryService.geographic_focus_data()).data;
                }
            },

            async load_more()
            {
                this.loading = true;

                await this.get_members();

                this.loading = false;
            },

            async apply_filters()
            {
                this.loading = true;
                this.page = 1;

                await this.get_members();

                this.loading = false;
            },

            async select_view(view)
            {
                await this.get_chart_data(view);

                switch (view)
                {
                    case 0:
                        this.chart_dataset = this.global_view_data;
                        break;
                    case 1:
                        this.chart_dataset = this.thematic_focus_data;
                        break;
                    case 2:
                        this.chart_dataset = this.geographic_focus_data;
                        break;
                }

                this.current_view = view;
            },

            get_member_picture(member)
            {
                return (member.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + member.profile_picture_id : 0)
            },
        }
    }
</script>

<template>
    <div
        class="icph-chart"
        :class="{
            'icph-loading': loading,
            'icph-expanded': expanded || fullyExpanded,
            'icph-fully-expanded': fullyExpanded,
            'icph-show-scrolling-info': show_scroll_info,
            }"
        :data-layout="layout"
        >
        <loader />
        <div
            class="icph-chart-viewport"
            :class="{'icph-list-members': this.members.length}"
            ref="viewport"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icph-svg-chart"
                :class="`icph-${layout}-layout`"
                :width="chart_width"
                :height="chart_height"
                :viewBox="view_box"
                ref="chart"
                >
                <slot name="before" />
                <g class="icph-svg-viewport"></g>
                <slot name="after" />
            </svg>
            <div class="icph-chart-tooltip" v-if="layout === 'force'">
                <img src="" />
                <div class="icph-tooltip-info">
                    <p class="icph-tt-name"></p>
                    <p class="icph-tt-title"></p>
                    <p class="icph-tt-company"></p>
                </div>
            </div>
            <ul class="icph-regional-leads" :class="{'icph-visible': regional_leads && regional_leads.length}" data-title="Regional Leads">
                <li v-for="(lead, key) in regional_leads" :key="key"><img :src="get_member_picture(lead) || require('@/assets/images/profile-no-plus.svg')" /> {{ lead.name }}</li>
                <!-- <li v-for="(lead, key) in regional_leads" :key="key"><img :src="get_member_picture(lead)" /> {{ lead.name }}</li> -->
            </ul>
            <team-member
                class="icph-chart-member"
                v-if="member && member.id"
                :member="member.id"
                :image="get_member_picture(member)"
                :name="member.name"
                :work-title="''"
                :lead="member.lead"
                :company="member.organization"
                :tags="member.thematic_scope"
                :key="key"
                >
            </team-member>
            <Transition>
                <perfect-scrollbar :options="{ suppressScrollX: true }" v-if="members.length">
                    <team v-if="members" :loading="loading">
                        <team-member
                            v-for="(member, key) in members"
                            :member="member.id"
                            :image="get_member_picture(member)"
                            :name="member.name"
                            :work-title="''"
                            :lead="member.lead"
                            :company="member.organization"
                            :tags="member.thematic_scope"
                            :key="key"
                            >
                        </team-member>
                        <Button class="icph-load-more" v-if="has_more_pages" color="primary" @click.prevent="load_more">
                            Load More
                        </Button>
                    </team>
                </perfect-scrollbar>
            </Transition>
        </div>
        <div class="icph-toolbar">
            <button class="icph-go-back" @click="go_back">GO BACK</button>
            <ul class="icph-breadcrumb">
                <li v-for="(title) in breadcrumb_path.split(',')" :key="title">{{ title }}</li>
            </ul>
            <div class="icph-zoom" v-if="layout !== 'tree' && !members.length">
                <button class="icph-zoom-in" @click="zoom_in">+</button>
                <button class="icph-zoom-out" @click="zoom_out">-</button>
            </div>
            <button class="icph-expand" v-if="!fullyExpanded" @click="expanded = !expanded">{{ expanded ? 'SHRINK' : 'EXPAND' }}</button>
        </div>
        <div
            :class="{
                'icph-hidden': !overlay_visible
            }"
            class="icph-chart-overlay"
             @click="overlay_visible = false"
            >
            <!-- <p></p> -->
            <button class="icph-chart-view" @click="overlay_visible = false">View Data Visualisation</button>
        </div>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';
    import Team from '@/components/Team/Team';
    import TeamMember from '@/components/Team/SmallTeamMember';
    import Button from '@/components/Buttons/Button';

    import * as d3 from "d3";

    import ForceLayout from '@/components/ICPHChart/layouts/ForceLayout.js';
    import TreeLayout from '@/components/ICPHChart/layouts/TreeLayout.js';
    import CirclePackingLayout from '@/components/ICPHChart/layouts/CirclePackingLayout.js';

    import { UserService } from '@/services';

    const MIN_ZOOM_LEVEL = 0.25;
    const MAX_ZOOM_LEVEL = 10;

    const LAYOUT_NAMES = {
        'force': 'Global View',
        'tree': 'Thematic Focus',
        'circle-packing': 'Geographic Focus',
    };

    export default
    {
        name: 'icph-chart',
        components:
        {
            Loader,
            Team,
            TeamMember,
            Button,
        },
        props:
        {
            loading: Boolean,
            fullyExpanded: Boolean,
            modelValue: null,
            dataset:
            {
                type: Array,
                required: false,
            },
            layout:
            {
                type: String,
                default: 'force',
            },
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                zoom: null,
                chart_width: 1010,
                chart_height: 666,
                view_box: [0, 0, 0, 0],
                per_page: 15,
                last_page: 1,
                page: 1,
                regional_leads: [],
                members: [],
                member: {},
                filters: {
                    country: '',
                },
                breadcrumb_path: LAYOUT_NAMES[this.layout],
                locations: null,
                mousedown: false,
                expanded: false,
                expand_popup: false,
                overlay_visible: true,
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            is_empty()
            {
                return !this.loading && !this.dataset.length;
            },
        },
        mounted()
        {
            this.view_box = [-this.chart_width / 2, -this.chart_height / 2, this.chart_width, this.chart_height];

            if (this.layout === 'force')
            {
                this.active_layout = ForceLayout;
            }
            else if (this.layout === 'tree')
            {
                // this.chart_height = 310;
                this.chart_height = this.chart_height - 120;
                this.view_box = [-0.5, -1, this.chart_width, this.chart_height + 30];
                this.active_layout = TreeLayout;
            }
            else if (this.layout === 'circle-packing')
            {
                this.active_layout = CirclePackingLayout;
            }

            this.active_layout.create(this.chart_width, this.chart_height, this.dataset);
            this.active_layout.set_on_update_value(this.update_value);

            if (this.layout !== 'tree')
            {
                this.zoom = d3.zoom()
                    .scaleExtent([MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL])
                    .on('zoom', (ev) =>
                    {
                        d3.select('.icph-svg-chart .icph-svg-viewport')
                            .attr('transform', ev.transform);
                    });

                // Init zoom
                d3.select('.icph-svg-chart')
                    .call(this.zoom)
                    .call(this.zoom.transform, d3.zoomIdentity.translate(0, 0).scale(0.6));
            }

            // this.update(false);

            // this.get_members();

        },
        methods:
        {
            async get_members()
            {
                const response = (await UserService.all({
                    page: this.page,
                    // term: this.search_term,
                    // countries: this.model.countries,
                    // topic: this.model.topic,
                    // icp_role: this.model.icp_role,
                    'per-page': 15,
                    'with-role': 'admin,global-team'
                })).data;
                const members = response.data;

                if (this.page === 1)
                {
                    this.members = members;
                }
                else
                {
                    this.members.push(...members);
                }

                this.last_page = response.meta.last_page;
                this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async load_more()
            {
                // this.loading = true;
                
                await this.get_members();
                
                // this.loading = false;
            },
            
            move_up()
            {
                d3.select('.icph-svg-chart')
                    .transition()
                    .call(this.zoom.translateBy, 0, -50);
            },

            move_down()
            {
                d3.select('.icph-svg-chart')
                    .transition()
                    .call(this.zoom.translateBy, 0, 50);
            },

            move_left()
            {
                d3.select('.icph-svg-chart')
                    .transition()
                    .call(this.zoom.translateBy, -50, 0);
            },

            move_right()
            {
                d3.select('.icph-svg-chart')
                    .transition()
                    .call(this.zoom.translateBy, 50, 0);
            },

            zoom_in()
            {
                d3.select('.icph-svg-chart')
                    .transition()
                    .call(this.zoom.scaleBy, 2);
            },

            zoom_out()
            {
                d3.select('.icph-svg-chart')
                    .transition()
                    .call(this.zoom.scaleBy, 0.5);
            },

            go_back()
            {
                this.active_layout.go_back();
            },

            update_value(value)
            {
                if (this.layout === 'force')
                {
                    this.update_breadcrumb(value.name || '');

                    if (value && value.name)
                    {

                        // value.thematic_scope = JSON.parse(value.thematic_scope);

                        this.member = value;
                    }
                    else
                    {
                        this.member = {};
                    }
                }
                else if (this.layout === 'tree')
                {
                    const names = [];
                    let d = value;
                    
                    while (d !== null)
                    {
                        names.push(d.data.name);
                        
                        d = d.parent;
                    }

                    names.pop();

                    this.update_breadcrumb(names.reverse());

                    if (value && value.depth > 0)
                    {
                        this.members = value.children.map((item) =>
                        {
                            if (typeof item.data.thematic_scope === 'string')
                            {
                                item.data.thematic_scope = JSON.parse(item.data.thematic_scope);
                            }

                            return item.data;
                        });
                    }
                    else
                    {
                        this.members = [];
                    }
                }
                else if (this.layout === 'circle-packing')
                {
                    const names = [];
                    let d = value;
                    
                    while (d !== null)
                    {
                        names.push(d.data.name);
                        
                        d = d.parent;
                    }

                    names.pop();

                    this.update_breadcrumb(names.reverse());

                    if (value && value.depth === 2)
                    {
                        this.regional_leads = value.children.map((d) => d.data.users.pop()).slice(0, 5);
                    }
                    else
                    {
                        this.regional_leads = [];
                    }

                    if (value && value.depth > 1 && value.data.users)
                    {
                        this.members = value.data.users.map((item) =>
                        {
                            if (typeof item.thematic_scope === 'string')
                            {
                                item.thematic_scope = JSON.parse(item.thematic_scope);
                            }

                            return item;
                        });
                    }
                    else
                    {
                        this.members = [];
                    }
                }
            },

            update_breadcrumb(path)
            {
                const base = [LAYOUT_NAMES[this.layout]];

                path = base.concat(Array.isArray(path) ? path : path.split(','));

                this.breadcrumb_path = path.join(',').replace(/,+$/, '');
            },

            get_member_picture(member)
            {
                if (member)
                {
                    return (member.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + member.profile_picture_id : 0)
                }

                return 0;
            },
        },
    }
</script>

import * as d3 from 'd3';

let width = 0;
let height = 0;
let x;
let y;

const TreeLayout =
{
    create(wh, he, data)
    {
        width = wh;
        height = he;
        // height = 280;

        // Compute the layout.
        const hierarchy = d3.hierarchy(data)
            .sum(d => Math.max(11, d.value))
            .sort((a, b) => b.value - a.value);
            
        const root = d3.treemap()
            .tile(this.tile)(hierarchy);

        // Create the scales.
        x = d3.scaleLinear().rangeRound([0, width]);
        y = d3.scaleLinear().rangeRound([0, height]);

        const svg = d3.select('.icph-svg-chart')
            .attr('viewBox', [0.5, -1, width, height + 30])
            .attr('width', width)
            .attr('height', height + 30)
            .attr('style', 'max-width: 100%; height: auto;')
            .style('font', '10px sans-serif');

        const group = svg.select('.icph-svg-viewport');

        this.group = group;

        this.render(group, root);

        this.svg = svg;

        // const marginTop = 1;
        // const marginRight = 1;
        // const marginBottom = 1;
        // const marginLeft = 1;
        
        // // Create the color scale.
        // const scheme_color = ['#185055', '#CBE1CF', '#DEC4D8', '#DDD5C4', '#F2C4B8', '#C3CCDD', '#D7E5E6', '#DDD5C4'];
        // color = d3.scaleOrdinal(scheme_color).domain(data.map(d => d.segment));


        // console.log('color', d3.schemeCategory10, color);
        
        // // Compute the layout.
        // const treemap = data => d3.treemap()
        //     .round(true)
        //     .tile(d3.treemapSliceDice)
        //     .size([
        //         width - marginLeft - marginRight, 
        //         height - marginTop - marginBottom
        //     ])(d3.hierarchy(d3.group(data, d => d.market, d => d.segment)).sum(d => d.value))
        //     .each(d => {
        //         d.x0 += marginLeft;
        //         d.x1 += marginLeft;
        //         d.y0 += marginTop;
        //         d.y1 += marginTop;
        //     });
        // const root = treemap(data);
        
        // // Create the SVG container.
        // const svg = d3.select('.icph-svg-chart')
        //     .attr('viewBox', [0, 0, width, height])
        //     .attr('width', width)
        //     .attr('height', height)
        //     .attr('style', 'max-width: 100%; height: 100%; font: 10px sans-serif;');
            
        //     // Position the nodes.
        // const node = svg.selectAll('g')
        //     .data(root.descendants())
        //     .join('g')
        //     .attr('transform', d => `translate(${d.x0},${d.y0})`);
            
        // // Draw leaves.
        // const cell = node.filter(d => d.depth === 1);
        
        // cell.append('rect')
        //     .attr('rx', 10)
        //     // .attr('fill', (d, i) => color[i])
        //     .attr('fill', d => color(d.data[0]))
        //     .attr('stroke', '#cbe1cf')
        //     // .attr('fill-opacity', (d) => d.value / d.parent.value)
        //     .attr('width', d => d.x1 - d.x0 - 1)
        //     .attr('height', d => d.y1 - d.y0 - 1);
            
        // cell.append('text')
        //     .attr('x', 3)
        //     .attr('y', '1.1em')
        //     .text(d => d.data[0]);

        return svg.node();
    },

    tile(node, x0, y0, x1, y1)
    {
        d3.treemapBinary(node, 0, 0, width, height);

        for (const child of node.children)
        {
            child.x0 = x0 + child.x0 / width * (x1 - x0);
            child.x1 = x0 + child.x1 / width * (x1 - x0);
            child.y0 = y0 + child.y0 / height * (y1 - y0);
            child.y1 = y0 + child.y1 / height * (y1 - y0);
        }
    },

    render(group, root)
    {
        // const scheme_color = ['#185055', '#CBE1CF', '#DEC4D8', '#DDD5C4', '#F2C4B8', '#C3CCDD', '#D7E5E6', '#DDD5C4'];
        const scheme_color = [
            '#c5e1cd',
            '#f9daa5',
            '#0d676c',
            '#b1cdd1',
            '#8ea568',
            '#c6cbaa',
            '#ffb461',
            '#ffb29a',
            '#ccb691',
            '#f2f2f2',
            '#342947',
        ];
        // const scheme_color = ['#185055', '#CBE1CF', '#DEC4D8', '#DDD5C4', '#F2C4B8', '#C3CCDD', '#D7E5E6', '#DDD5C4'];
        const color = d3.scaleOrdinal(scheme_color).domain(root.children.map(d => d.segment));

        this.root = root;

        const node = group
            .selectAll('g')
            .data(root.children)
            .join('g');
    
        node.filter(d => d === root ? d.parent : d.children)
            .attr('cursor', 'pointer')
            .on('click', (_, d) =>
            {
                this.update_cb({ ...d });

                if (d.depth < 2)
                {
                    this.zoomin(d);
                }
            });

        const id = Math.floor(Math.random() * 10000) + 'pl';
    
        node.append('rect')
            .attr('id', id)
            .attr('rx', 10)
            .attr('fill', d => color(d.data))
            .attr('stroke', '#cbe1cf');
    
        node.append('clipPath')
            .attr('id', d => (d.clipUid = d3.select('clip')).id)
          .append('use')
            .attr('xlink:href',  id);
    
        // node.append('text')
        //     .attr('clip-path', d => d.clipUid)
        //     .attr('font-weight', d => d === root ? 'bold' : null)
        //   .selectAll('tspan')
        //   .data(d => d.data.name)
        //   .join('tspan')
        //     .attr('x', 3)
        //     .attr('y', (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`)
        //     .attr('fill-opacity', (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
        //     .attr('font-weight', (d, i, nodes) => i === nodes.length - 1 ? 'normal' : null)
        //     .text(d => d);


        const object = node.append('foreignObject')
            .attr('x', 0)
            .attr('y', 0);

        object.append('xhtml:p')
            .attr('class', (d) => 'icph-tree-label' + (color(d.data) === '#0d676c' || color(d.data) === '#342947' ? ' icph-light-text' : ''))
            .attr('xmlns', 'http://www.w3.org/1999/xhtml')
            .text(d => d.data.name);

        // <foreignObject x="10" y="10" width="100" height="150">
        //     <div xmlns="http://www.w3.org/1999/xhtml">
        //     Here is a <strong>paragraph</strong> that requires <em>word
        //     wrap</em> and has much more text to wrap and sence
        //     to wrappin well
        //     </div>
        // </foreignObject>

        // node.append('text')
        //     .attr('x', 22)
        //     .attr('y', 17)
        //     .text(d => d.data.name);

        this.position(group, root);
    },

    position(group, root)
    {
        // const x = d3.scaleLinear().rangeRound([0, width]);
        // const y = d3.scaleLinear().rangeRound([0, height]);

        group.selectAll('g')
            .attr('transform', d => d === root ? `translate(0,-30)` : `translate(${x(d.x0)},${y(d.y0)})`)
          .select('rect')
            .attr('width', d => x(d.x1) - x(d.x0) - 5)
            .attr('height', d => y(d.y1) - y(d.y0) - 5);

        // group.selectAll('text')
        //     .attr('x', (d, i, nodes) => x(d.x1) - x(d.x0) - nodes[i].getBBox().width - 22);
        group.selectAll('foreignObject')
            // .attr('x', (d, i, nodes) => x(d.x1) - x(d.x0) - nodes[i].getBBox().width - 22)
            .attr('width', d => x(d.x1) - x(d.x0) - 5)
            .attr('height', d => y(d.y1) - y(d.y0) - 5);
    },
    
    zoomin(d)
    {
        // const x = d3.scaleLinear().rangeRound([0, width]);
        // const y = d3.scaleLinear().rangeRound([0, height]);
        const group0 = this.group.attr('pointer-events', 'none');
        const group1 = this.group = this.svg.append('g');

        this.render(this.group, d);
    
        x.domain([d.x0, d.x1]);
        y.domain([d.y0, d.y1]);
    
        this.svg.transition()
            .duration(750)
            .call(t => group0.transition(t).remove()
              .call(this.position, d.parent))
            .call(t => group1.transition(t)
              .attrTween('opacity', () => d3.interpolate(0, 1))
              .call(this.position, d));
    },
    
    zoomout(d)
    {
        // const x = d3.scaleLinear().rangeRound([0, width]);
        // const y = d3.scaleLinear().rangeRound([0, height]);
        const group0 = this.group.attr('pointer-events', 'none');
        const group1 = this.group = this.svg.insert('g', '*');

        this.render(this.group, d.parent);
    
        x.domain([d.parent.x0, d.parent.x1]);
        y.domain([d.parent.y0, d.parent.y1]);
    
        this.svg.transition()
            .duration(750)
            .call(t => group0.transition(t).remove()
              .attrTween('opacity', () => d3.interpolate(1, 0))
              .call(this.position, d))
            .call(t => group1.transition(t)
              .call(this.position, d.parent));
    },

    set_on_update_value(cb)
    {
        this.update_cb = cb;
    },
    
    go_back()
    {
        if (this.root && this.root.parent)
        {
            this.update_cb(this.root.parent);
            this.zoomout(this.root);
        }
    },
};

export default TreeLayout;
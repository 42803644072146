<template>
    <div
        class="icph-team-member icph-small"
        :class="{
            'icph-contact-member': show_contact_member,
            'icph-team-member-selected': show_contact_member,
            'icph-no-image': !image,
        }"
        >
        <div class="icph-team-head">
            <img :src="image || require('@/assets/images/profile-no-plus.svg')" />
            <div class="icph-team-info">
                <span class="icph-team-name" v-if="name">{{ name }}</span>
                <span class="icph-team-work-title" v-if="workTitle">{{ workTitle }}</span>
                <span class="icph-team-company" v-if="company">{{ company }}</span>
            </div>
        </div>
        <!-- <div class="icph-lead" v-if="lead">
            <p>ICPH Lead</p>
            <ul class="icph-lead-list">
                <li v-for="(l, index) in lead" :key="'l_' + index">{{ l }}</li>
            </ul>
        </div> -->
        <ul class="icph-team-tags">
            <li class="icph-team-tag" v-for="(tag, index) in tags" :key="index">{{ tag }}</li>
        </ul>
    </div>
</template>

<script>
    export default
    {
        name: 'team-member',
        props:
        {
            member: null,
            image: null,
            name: String,
            workTitle: String,
            company: String,
            tags: [Array, String],
            lead: [Array, String],
        },
        data()
        {
            return {
            };
        },
        methods:
        {
        },
    }
</script>

import { ApiService } from './api-service';

const NetworkDirectoryService =
{
    global_view_data(params)
    {
        return ApiService.get('/api/network-directory/global-view', params);
    },

    thematic_focus_data(params)
    {
        return ApiService.get('/api/network-directory/thematic-focus', params);
    },

    geographic_focus_data(params)
    {
        return ApiService.get('/api/network-directory/geographic-focus', params);
    },
};

export {NetworkDirectoryService};

import * as d3 from 'd3';

const CirclePackingLayout =
{
    create(width, height, data)
    {
        // Create the color scale.
        const color = d3.scaleLinear()
            .domain([0, 5])
            .range(['#CBE1CF', '#CBE1CF50', '#f00'])
            .interpolate(d3.interpolateHcl);
    
        // Compute the layout.
        const pack = data => d3.pack()
            .size([width, height])
            .padding(d => d.depth === 0 ? 20 : 8)(d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value));

        const root = pack(data);

        // Create the SVG container.
        const svg = d3.select('.icph-svg-viewport')
            .attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)
            .attr('width', width)
            .attr('height', height);
        // .attr('style', `max-width: 100%; height: auto; display: block; margin: 0 -14px; background: ${color(0)}; cursor: pointer;`);
        
        // Append the nodes.
        const node = svg.append('g')
            .selectAll('circle')
            .data(root.descendants().slice(1))
            .join('circle')
                .attr('fill', d => d.children ? color(d.depth) : '#2E656B44')
                .attr('stroke', d => d.depth === 1 ? '#CBE1CF55' : null)
                // .attr('pointer-events', d => !d.children ? 'none' : null)
                .on('mouseover', function() { d3.select(this).attr('stroke', '#CBE1CFAA'); })
                .on('mouseout', function(_, d) {  d3.select(this).attr('stroke', d.depth > 1 ? null : '#CBE1CF55'); })
                .on('click', (ev, d) =>
                {
                    this.update_cb({ ...d });

                    if (d.depth < 3)
                    {
                        if (focus !== d)
                        {
                            zoom(ev, d)
                        }
                    }

                    focus = d;
                    this.focus = d;

                    ev.stopPropagation();
                });
            
        // Append the text labels.
        const label = svg.append('g')
            .style('font', '10px sans-serif')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .selectAll('text')
            .data(root.descendants())
            .join('text')
                .style('fill-opacity', d => d.parent === root ? 1 : 0)
                .style('display', d => d.parent === root ? 'inline' : 'none')
                .text(d => d.data.name);
            
        // Create the zoom behavior and zoom immediately in to the initial focus node.
        svg.on('click', (event) => zoom(event, root));

        let focus = root;
        this.focus = focus;
        let view;

        zoomTo([focus.x, focus.y, focus.r * 2]);

        function zoomTo(v)
        {
            const k = width / v[2];
            
            view = v;
            
            label.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
            node.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
        
            node.attr('r', d => d.r * k);
        }

        const zoom = (event, d) =>
        {
            focus = d;
            this.focus = focus;
        
            const transition = svg.transition()
                .duration(event.altKey ? 7500 : 750)
                .tween('zoom', () =>
                {
                    const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
                    
                    return t => zoomTo(i(t));
                });
                
            label.filter(function(d) { return d.parent === focus || this.style.display === 'inline'; })
                .transition(transition)
                .style('fill-opacity', d => d.parent === focus ? 1 : 0)
                .on('start', function(d) { if (d.parent === focus) this.style.display = 'inline'; })
                .on('end', function(d) { if (d.parent !== focus) this.style.display = 'none'; });
        };

        this.zoom = zoom;

        return svg.node();
    },

    set_on_update_value(cb)
    {
        this.update_cb = cb;
    },

    go_back()
    {
        if (this.focus && this.focus.parent)
        {
            this.update_cb(this.focus.parent);
            this.zoom({}, this.focus.parent);
        }
    },
};

export default CirclePackingLayout;
import * as d3 from "d3";

const NO_PROFILE_DEFAULT_IMAGE = require('@/assets/images/profile-no-plus.svg');

const ForceLayout =
{
    create(width, height, data)
    {
        const root = d3.hierarchy(data);
        const links = root.links();
        const nodes = root.descendants();
        
        const simulation = d3.forceSimulation(nodes)
            .velocityDecay(0.8)
            .force('link', d3.forceLink(links).id(d => d.id).distance(0).strength(1))
            .force('charge', d3.forceManyBody().strength(-500))
            // .force('center', d3.forceCenter(width / 2, height / 2))
            .force('x', d3.forceX())
            .force('y', d3.forceY());

        const svg = d3.select('.icph-svg-chart .icph-svg-viewport');
            
        const link = svg.append('g')
            .attr('stroke', '#fff')
            .attr('fill', '#cbe1cf')
            .selectAll('line')
            .data(links)
            .join('line')
                .attr('stroke-width', d => d.source.depth === 0 ? 3 : 1);
            
        const node = svg.append('g')
            .selectAll('.circle')
            .data(nodes)
            .join('g')
                .attr('data-depth', d => d.depth)
                .attr('fill', '#cbe1cf')
                .attr('class', 'circle')
                .on('click', (_, d) =>
                {
                    this.update_cb(d.data);
                })
                .on('mouseover', (_, d) =>
                {
                    d3.select('.icph-chart-tooltip').node().classList.add('icph-visible');

                    d3.select('.icph-chart-tooltip .icph-tt-name').text(d.data.name);
                    d3.select('.icph-chart-tooltip .icph-tt-company').text(d.data.organization || '');
                    d3.select('.icph-chart-tooltip img').attr('src', d.data.profile_picture_id ? this.get_member_picture(d.data) : NO_PROFILE_DEFAULT_IMAGE);
                })
                .on('mousemove', (ev) =>
                {
                    const rect = d3.select('.icph-chart-tooltip').node().getBoundingClientRect();

                    d3.select('.icph-chart-tooltip')
                        .style('left', (ev.offsetX + 23) + 'px')
                        .style('top', (ev.offsetY - (rect.height / 2)) + 'px');
                })
                .on('mouseleave', () =>
                {
                    d3.select('.icph-chart-tooltip').node().classList.remove('icph-visible');
                });

            // d3.select('.icph-chart-viewport').on('mousemove', (ev) =>
            // {
            //     const target = ev.target;

            //     if (!target || !target.closest('.icph-chart-tooltip') && !target.closest('g.circle'))
            //     {
            //         d3.select('.icph-chart-tooltip').node().classList.remove('icph-visible');
            //     }
            // });

        node.append('circle')
            .attr('fill', '#cbe1cf')
            .attr('r', 17.19);
                
        node.append('text')
            .text(d => d.data.name !== 'ICPH' ? d.data.name.substr(0, 2) : d.data.name);

        const labels = nodes.filter((d) => d.depth === 1);

        const node_labels = svg.append('g')
            .selectAll('.node-label')
            .data(labels)
            .join('g')
                .attr('class', 'node-label')

        node_labels.append('rect')
            .attr('fill', '#fff')
            .attr('rx', 22)
            .attr('width', '208.036')
            .attr('height', '55.607');

        node_labels.append('path')
            .attr('fill', '#fff')
            .attr('d', 'M11.954,0,23.909,35.478H0Z')
            .attr('transform', 'matrix(-0.839, -0.545, 0.545, -0.839, 109.531, 74.347)');

        node_labels.append('text')
            .attr('x', '104')
            .attr('y', '28')
            .text(d => d.data.name);

            
        simulation.on('tick', () =>
        {
            simulation.alpha(0.1);

            link
                .attr('x1', d => d.source.y)
                .attr('y1', d => d.source.x)
                .attr('x2', d => d.target.y)
                .attr('y2', d => d.target.x);
                
            node.attr('transform', (d) => `translate(${d.y}, ${d.x})`);
            node_labels.attr('transform', (d) => `translate(${d.y - 100}, ${d.x - 70})`);
            node_labels.selectAll('rect')
                .attr('fill', d => d.data.color);
            node_labels.selectAll('path')
                .attr('fill', d => d.data.color);
        });
        
        // invalidation.then(() => simulation.stop());
        
        return svg.node();
    },

    set_on_update_value(cb)
    {
        this.update_cb = cb;
    },

    go_back()
    {
        this.update_cb(false);
    },

    get_member_picture(member)
    {
        return (member.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + member.profile_picture_id : 0)
    },
};

export default ForceLayout;